.ReactTable {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ReactTable * {
  box-sizing: border-box;
}

.color-naranja-nosotros {
  color: #e46e1f;
}

.text-contacto,
.flor {
  font-size: 22px;
  max-width: 733px;
  text-align: justify;
}

.ReactTable .rt-table {
  -webkit-box-flex: 1;
  -ms-flex: auto 1;
  flex: auto 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.ReactTable .rt-thead::-webkit-scrollbar {
  background: transparent;
}

.coloruno {
  color: #ec9257;
}

.colordos {
  color: #6933ad;
}

.ReactTable .rt-thead {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-y: scroll;
  scrollbar-width: none;

  scrollbar-track-color: #fff;
  scrollbar-3dlight-color: #fff;
  scrollbar-darkshadow-color: #fff;
  scrollbar-arrow-color: #fff;
}

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0;
}

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-thead .rt-tr {
  text-align: center;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
}

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-thead .rt-th:focus {
  outline-width: 0;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px;
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px;
}

.ReactTable .rt-tbody {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: none;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}

.ReactTable .rt-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.ReactTable .rt-tr {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}

.ReactTable .rt-expander:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}

.ReactTable .rt-tfoot {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05);
}

.ReactTable .-pagination {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0;
}

.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline-width: 0;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.ReactTable .-pagination .-center {
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5;
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading>div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active>div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bg-light {
  background-color: #e1eeff !important;
}

span.input-group-text {
  color: #1f5f94;
}

.modal-confirm {
  color: #636363;
  width: 400px;
}

.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}

.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}

.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}

.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}

.modal-confirm .modal-body {
  color: #999;
}

.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}

.modal-confirm .modal-footer a {
  color: #999;
}

.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}

.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}

.modal-confirm .icon-box-green {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #34caa5;
}

.modal-confirm .icon-box-green i {
  color: #34caa5;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}

.modal-confirm .icon-box-yellow {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #ffb22b;
}

.modal-confirm .icon-box-yellow i {
  color: #ffb22b;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}

.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 35px;
  border-radius: 3px;
  margin: 0 5px;
}

.modal-confirm .btn-secondary {
  background: #c1c1c1;
}

.modal-confirm .btn-secondary:hover,
.modal-confirm .btn-secondary:focus {
  background: #a8a8a8;
}

.modal-confirm .btn-primary {
  background: #116eaa;
}

.modal-confirm .btn-primary:hover,
.modal-confirm .btn-primary:focus {
  background: #0b4e7a;
}

.modal-confirm .btn-danger {
  background: #ee3535;
}

.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #ee3535;
}

.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

/* Estilos para el buscador en mantenimientos */
.textoBuscador {
  color: #1485cd;
}

.inputIzquierdaBuscador {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.inputIzquierdaBuscador2 {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.iconoBuscarBuscador {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.camposBuscador {
  height: calc(1.5em + 0.75rem + 10px) !important;
}

.camposBuscador2 {
  height: calc(1.15em + 0.75rem + 10px) !important;
}

.buttonpermisos {
  height: calc(1.05em + 0.75rem + 10px) !important;
}

/*Estilos circulo de notificaciones*/
.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  border: 0.25px;
}

.dot-primary {
  color: #fff;
  background-color: #1485cd;
}

.dot-primary[href]:hover,
.dot-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1485cd;
}

.dot-secondary {
  color: #fff;
  background-color: #545b62;
}

.dot-secondary[href]:hover,
.dot-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.dot-success {
  color: #fff;
  background-color: #06d79c;
}

.dot-success[href]:hover,
.dot-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #06d79c;
}

.dot-info {
  color: #fff;
  background-color: #ffb22b;
}

.dot-info[href]:hover,
.dot-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ffb22b;
}

.dot-warning {
  color: #fff;
  background-color: #ffb22b;
}

.dot-warning[href]:hover,
.dot-warning[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ffb22b;
}

.dot-danger {
  color: #fff;
  background-color: #ef5350;
}

.dot-danger[href]:hover,
.dot-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ef5350;
}

/* Accesos directos del dashboard */
.acceso-directo {
  cursor: pointer;
}

.acceso-directo:hover,
.acceso-directo:focus {
  color: #1485cd;
}

/*Imagen de usuario */
.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
  float: left;
}

.avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-edit input {
  display: none;
}

.avatar-edit label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-edit label i {
  margin-top: 8px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 50px auto;
    float: initial;
  }
}

.avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-preview img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/images/users/5.jpg");
}

@keyframes flame {
  0% {
    text-shadow: 0 0 5px rgba(255, 69, 0, 0.6), 0 0 10px rgba(255, 165, 0, 0.6), 0 0 15px rgba(255, 215, 0, 0.6), 0 0 20px rgba(255, 255, 0, 0.6);
  }
  50% {
    text-shadow: 0 0 10px rgba(255, 69, 0, 0.9), 0 0 20px rgba(255, 165, 0, 0.9), 0 0 30px rgba(255, 215, 0, 0.9), 0 0 40px rgba(255, 255, 0, 0.9);
  }
  100% {
    text-shadow: 0 0 5px rgba(255, 69, 0, 0.6), 0 0 10px rgba(255, 165, 0, 0.6), 0 0 15px rgba(255, 215, 0, 0.6), 0 0 20px rgba(255, 255, 0, 0.6);
  }
}

.flame-animation {
  animation: flame 1s ease-in-out infinite alternate;
  color: transparent;
}


/*Estilos para mostrar spinner al cargar*/

#divCargando {
  width: 100%;
  height: 100%;

  /*-Lets Center the Spinner-*/
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  /*Centering my shade */
  margin-bottom: 40px;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#divCargando::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 80px;
  height: 80px;
  border-style: solid;
  border: 5px solid black;
  border-top-color: #6cc4ee;
  border-width: 7px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.ant-table-header {
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
}

.white_space_normal {
  white-space: normal !important;
}

.rt-td.center-cell {
  text-align: center !important;
}

/*
estilos para tabla de productos
*/
/* .rt-td{
  display:flex;
  justify-content: center;
  align-items: center;
} */
/* comentado para despues */

.name-cell {
  word-break: break-all !important;
  white-space: inherit;
}

.img-cell {
  text-align: center;
  vertical-align: center;
}

.center-cell {
  text-align: center;
}

.img-cell img {
  width: 100px;
}

.ant-table-thead tr th {
  text-align: center;
}

.link-settings:hover {
  background-color: #6f42c1;
  color: #ffffff;
  cursor: pointer;
}

.setting-icon {
  cursor: pointer;
}

.a-active {
  color: #0374ae !important;
}

.a-inactive {
  color: black !important;
  opacity: 0.5;
}

.antd-dropdown-icon-text {
  position: relative;
  /* Adjust these values accordingly */
  /* top: -1px; */
  /* left: 2px; */
  /* bottom: 4px; */
}

.antd-dropdown-icon-text-left {
  position: relative;
  /* Adjust these values accordingly */
  top: -1px;
  left: 2px;
  /* bottom: 4px; */
}

.ant-table-tbody>tr.ant-table-row-level-0:hover>td {
  background-color: #f4f6f9;
}

.antd-primary-color {
  background-color: #662d91;
}

span.required {
  color: red;
  font-size: 0.8rem;
}

span.required-danger {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ef5350;
}

span.succes {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: greenyellow;
}


/* .required {
  color: red;
  font-size: 0.8rem;
} */
.label-form {
  display: inline;
  padding-top: 0 !important;
  font-size: 0.8rem;
  font-weight: normal;
}

.card-body.panel {
  background-color: white;
  font-weight: lighter;
}

.form-select {
  display: block;
  width: 100%;
  height: 10px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #525f7f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e9ecef;
  border-radius: 2px;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ant-select.form-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  padding-bottom: 30px;
}

/* .ant-collapse-content-box {
  background-color: white;
} */

.panel-title {
  font-weight: bold;
}

/* .ant-table-row.ant-table-row-level-0:hover{
  background-color: #aaa !important;
} */

.my-icon-wrapper {
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
  /* align-items: center; */
}

.menu-item-operacion:hover {
  /* width: 2rem;
  height: 1rem; */
  justify-content: center;
  align-items: center;
  background-color: #6f42c1;
  color: #ffffff;
  cursor: pointer;
}

/* .form-editor{
  padding-top: 1rem;
} */
/* .wrapperClassName.rdw-editor-wrapper */
.editorClassName.rdw-editor-main {
  border-radius: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-width: 0.1rem;
}

.link-tooltip {
  height: auto;
  width: auto;
}

.text-input.error .css-bg1rzq-control {
  border-color: #ef5350;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ef5350' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ef5350' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

@media only screen and (max-width: 1100px) {
  #background-login {
    margin-left: -13% !important;
  }
}

@media only screen and (max-width: 920px) {
  #background-login {
    background-image: none !important;
  }
}

/* @media only screen and (min-height: 658px){
  #background-login{
    background-size: 50% 400px !important;
    margin-left: -8% !important;
  }
} */
/* 
@media only screen and (min-height: 300px){
  #background-login{
    background-size: 50% 74% !important;
  }
}

@media only screen and (min-height: 1000px){
  #background-login{
    background-size: 55% 45% !important;
  }
} */
/* @media (min-height: 1000px){
  #background-login{
    background-size: 46% 56% !important;
  }
} */

/* .ant-select-selector{
  border-radius: 4px !important;
  height: 36px !important;
}
.ant-select-selection-item{
  line-height: 36px !important;
  text-align: center;
} */

/* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4a2368;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-tab:hover {
  color: #f36f21;
} */

/* .ant-tabs-ink-bar {
  background: #662d91;
} */

@media (max-width: 760px) {
  .submenu-productos {
    margin-top: 55px !important;
  }

  #navbarnv {
    float: none !important;
    position: absolute;
    z-index: 1;
    /*-- New attribute --*/
    background-color: #393939;
    /*-- New attribute --*/
    padding: 10px 20px;
    width: 90%;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e9ecef;
    border-radius: 2px;
    box-shadow: 0 50px 100px rgb(50 50 93 / 10%),
      0 15px 35px rgb(50 50 93 / 15%), 0 5px 15px rgb(0 0 0 / 10%);
  }

  .collapsing {
    position: static;
  }

  /* .dropdown-menu.show {
    display: block;
  } */
}

.navli {
  color: #748088 !important;
}

.navli:hover {
  color: black !important;
  background-color: #e9ecef;
}

.help {
  z-index: 10;
  position: absolute;
  right: 14px;
  top: 0px;
  /* background-color: #f7f7f7; */
  /* color: black; */
  /* font-weight: lighter; */
  /* border-radius: 5px; */
  /* padding: 5px; */
  /* border-style: unset; */
}

#aggcarrito,
#ircarrito {
  background-color: white !important;
}

#aggcarrito:hover {
  /* background-color: white !important; */
  /* color: #662D91; */
  background-color: #662d91 !important;
}

#ircarrito:hover {
  background-color: #f36f21 !important;
}

.shopping {
  color: "#F36F21";
}

.shopping:hover {
  color: white;
}

.botones_tienda {
  z-index: 10;
  position: absolute;
  right: 0px;
  top: -30px;
  /* background-color: #f7f7f7; */
  /* color: black; */
  /* font-weight: lighter; */
  /* border-radius: 5px; */
  /* padding: 5px; */
  /* border-style: unset; */
}

.center-card {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  align-content: center;
  /* height: 200px; */
  /* border: 3px solid green;  */
}

.scroll-img>.row {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.scroll-img>.row>.opiniones {
  display: inline-block;
  float: none;
}

.opiniones:hover {
  transform: scale(1.1) !important;
}

#ttl {
  float: right;
}

.selected-row,
.selected-row>* {
  background: #d9fadd;
  /* overflow: auto; */
}

.ant-card-meta-title,
.dropdown-toggle {
  white-space: pre-line;
}

.custom-meta-title {
  white-space: pre-line;
}

.menu-tienda {
  position: fixed;
  top: 12%;
  z-index: 100;
  background: #4a2368;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}

.react-multiple-carousel__arrow:hover {
  border: 2px solid #4a2368 !important;
}

.react-multiple-carousel__arrow {
  /* position: absolute;
    outline: 0;
    transition: all .5s; */
  border-radius: 35px !important;
  z-index: 1 !important;
  border: 1px solid #662d91 !important;
  background: #ffffff00 !important;
  min-width: 32px !important;
  min-height: 32px !important;
  /* opacity: 1; */
  /* cursor: pointer; */
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}

.react-multiple-carousel__arrow::before {
  font-size: 16px !important;
  color: #4a2368 !important;
  /* display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative; */
}

/* 
#main-wrapper[data-layout='vertical'][data-sidebar-position='fixed'][data-sidebartype='overlay'] .topbar .top-navbar .navbar-collapse {
  margin-left: 65px; } */

.navbar-custom {
  height: 48px;
}

.topbar .top-navbar .navbar-nav>.nav-item>.nav-link {
  line-height: 48px;
  height: 48px;
  font-size: 14px;
}

/* #headerbuscar{
  border-radius: 25px !important;
  width: 533px;
} */
/* #headerbuscar:after {
  content: url("https://kipit.shop/home/img/image%2015.svg");
  position: absolute;
  left: 6px;
} */

/* #main-wrapper[data-layout="vertical"][data-sidebartype="overlay"].show-sidebar .left-sidebar {
  left: -240px;
} */

/* @media only screen and (max-width: 1060px) {

  #headerbuscar,
  #fa-search {
    display: none !important;
  }

  #main-wrapper[data-layout="vertical"][data-sidebartype="overlay"].show-sidebar .left-sidebar {
    left: -0 !important;
  }
} */

/* #main-wrapper[data-layout="vertical"] .topbar .top-navbar .navbar-header[data-logobg="skin6"] {
  background: #fff0;
} */

/* #main-wrapper[data-layout='vertical'][data-sidebartype='overlay'].left-sidebar .show-sidebar {
  left: -240px !important; 
} */

/* #sidebarbg {
  padding-top: 118px;
} */

@media (max-width: 767.98px) {
  /* #navbarbg {
    top: 60px !important;
  } */

  #navbarcategoria {
    display: none;
  }

  #main-wrapper[data-layout="vertical"][data-sidebartype="overlay"].show-sidebar .left-sidebar {
    left: -0 !important;
  }
}

/* #main-wrapper[data-layout="vertical"][data-sidebar-position="fixed"][data-sidebartype="full"] .topbar .top-navbar .navbar-collapse, #main-wrapper[data-layout="vertical"][data-sidebar-position="fixed"][data-sidebartype="overlay"] .topbar .top-navbar .navbar-collapse{
  margin-left: 0 !important;
} */

/* .page-wrapper>.page-content {
  padding: 0px;
} */

.ecom-header {
  background-color: white !important;
  border-bottom: 1px solid #f36f212b;
  color: "#662D91";
}

.breadcrum-icon {
  display: inline-flex;
  margin-right: 5px;
}

.footercont {
  min-width: 375px !important;
  overflow: auto;
}

@media (min-width: 1200px) {
  .footercont {
    max-width: 1440px !important;

  }

  .soporte {
    max-width: 18% !important;
  }
}

@media (max-width: 1199px) {
  .section1 {
    text-align: center;
  }

  .custom-shop {
    margin-left: auto !important;
  }
}

.fsubt {
  color: #bc90dd;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
}

.bodytext {
  color: #bc90dd;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.lh-lg {
  line-height: 40px !important;
}

.section1 {
  /* gap: 15px; */
  font-family: "Roboto";
  line-height: 18px;
}

.bg-primary {
  background-color: #440099 !important;
}

.bg-kpprimary {
  background-color: #0033A0 !important;
}

.text-kpprimary {
  color: #0033A0 !important;
}

/* #main-wrapper[data-layout="vertical"][data-sidebartype="overlay"] .topbar .top-navbar .navbar-header {
  width: 195px;
}

#main-wrapper[data-layout="vertical"][data-sidebartype="overlay"] .topbar .top-navbar .navbar-header {
  width: 195px;
} */

/* #main-wrapper[data-layout="vertical"][data-sidebar-position="fixed"][data-sidebartype="full"] .topbar .top-navbar .navbar-collapse,
#main-wrapper[data-layout="vertical"][data-sidebar-position="fixed"][data-sidebartype="overlay"] .topbar .top-navbar .navbar-collapse {
  margin-left: 195px;
} */

/* @media (min-width: 768px) {
  #main-wrapper[data-layout="vertical"][data-sidebartype="full"] .topbar .top-navbar .navbar-header {
    width: 195px;
  }
} */
/* @media (min-width: 768px) {
  #main-wrapper[data-layout=vertical][data-sidebartype=overlay] .topbar .top-navbar .navbar-header .logo-text {
    display: none;
  }
} */


/* .ant-steps-item-finish .ant-steps-item-icon { */
/* background-color: #198754; */
/* border-color: #fff; */
/* border: none; */
/* } */

/* .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon { */
/* color: #fff; */
/* display: contents; */
/* } */

/* .anticon{ 
  display: contents;
} */
/* 
.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  display: inline-flex;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  color: #662d91;
} */

.custom-pagination>.ant-pagination-item {
  display: none !important;
}

.capitalize {
  text-transform: capitalize;
}

.custom-pagination>.ant-pagination-prev .ant-pagination-item-link,
.custom-pagination>.ant-pagination-next .ant-pagination-item-link {
  border-radius: 35px !important;
  z-index: 1 !important;
  border: 1px solid #662d91 !important;
  background: #ffffff00 !important;
  min-width: 32px !important;
  min-height: 32px !important;
}

.custom-pagination>.ant-pagination-prev,
.custom-pagination>.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 34px;
}

.anticon svg {
  display: flex;
}

/* 
.custom-pagination > .ant-pagination-prev button, .ant-pagination-next button{
  color: #662d91 !important;
} */

@media (min-width: 992px) {
  .mvcol {
    max-width: 17.6666666667% !important;
  }
}

.favbtn {
  border-radius: 35px;
  min-width: 24px;
  min-height: 24px;
  padding: 0;
  font-size: 10px;
  color: #6b696d;
}

.favbtn:hover {
  background-color: transparent;
  color: #eb2f96;
  border-color: #6b696d;
}

.favbtn:active,
.favbtn.btn-outline-secondary:not(:disabled):not(.disabled):active,
.favbtn.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.favbtn.btn-outline-secondary.dropdown-toggle {
  background-color: white;
  color: #eb2f96;
  border-color: #cccccc;
}

.sku {
  font-family: "Roboto";
  font-size: 12px;
}

.custom-shop-title {
  font-size: 14px !important;
  height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-shop-title:hover {
  overflow: auto;
}

.btnNumber>.ant-input-number-handler-wrap {
  opacity: 1;
}

/* b {
  background: #fafafa !important;
} */

.card-price {
  font-weight: 700;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-price:hover {
  overflow: hidden;
}

.caption>h3 {
  color: white;
  /* display: inline-flex; */
  width: 453px;
  font-size: 30px !important;
  font-weight: 500;
}

z .caption>h1 {
  color: white;
  /* display: inline-flex; */
  /* width: 453px; */
  font-size: 38px !important;
  font-weight: 700;
}

.caption {
  bottom: 45px;
  position: absolute;
  right: 15%;
  /* bottom: 20px; */
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@media (max-width: 575px) {
  .caption>h3 {
    font-size: 20px !important;
    width: 345px !important;
  }

  .caption {
    bottom: 70px;
  }
}

.custom-card:hover {
  transform: scale(1.1);
}

/* .banner {
  height: 155px !important;
}
.bannerimg {
  height: 200px !important;
}
@media (min-width: 1200px) {
  .bannerimg {
    height: 264px !important;
  }
  .banner {
    height: 210px !important;
  }
}

@media (max-width: 1200px) {
  .banner {
    height: 210px !important;
  }
} */

@media (min-width: 575px) {
  .tiendafoot {
    align-items: flex-start !important;
    text-align: left !important;
    flex-direction: column !important;

  }

  .footimg {
    display: flex;
    align-items: center !important;
  }

}

@media (min-width: 992px) {
  .tiendafoot {
    align-items: center !important;
    text-align: center !important;
    flex-direction: row !important;
  }

  .footimg {
    display: block;
  }

}

body {
  font-family: 'Kanit', sans-serif;
  ;
}

/* .text-primary {
  color: #440099 !important;
} */

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  /* margin-bottom: 24px;
  overflow: hidden; */
  background: white;
  /* border: 0px;
  border-radius: 2px; */
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.bannerkp {
  height: auto;
}

@media (min-width: 992px) {
  .bannerkp {
    height: 267px !important;
  }

}

@media (max-width: 767px) {

  .topbar .top-navbar .dropdown-menu {
    width: auto;
  }
}

.card-estadisticas:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.card-estadisticas {
  border-width: .1rem;
  border-radius: .5rem;
  border-style: solid;
  border-color: #eeeeee;
}


.btn-close:hover {
  background-color: transparent;
  box-shadow: none;
  color: red;
}

.ant-modal-login>.ant-modal-content {
  background-color: transparent;
}

.ant-modal-template>.ant-modal-content {
  border-radius: 15px;
}

/* #descripcion_corto > * > strong{
  font-family: "Kanit" !important;
  font-weight: 400 !important;
} */

.redondeado {
  border-radius: .6rem;
}

.ordenestime {
  padding: .1rem 1.5rem;
  color: #fff;
  text-decoration: underline;
  border-radius: 5px;
}

.OrdenHeader>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container table>thead>tr>th {
  background: transparent;
  /* border-bottom: 2px solid #f0f0f0; */
}

/* .OrdenRows>.ant-table-cell { */
/* border-bottom: 3px solid #f0f0f0; */
/* } */

.OrdenAll {
  border: 2px solid #cccccc;
  border-radius: 5px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}

#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin6"] .sidebar-nav ul .sidebar-item .sidebar-link,
#main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin6"] .sidebar-nav ul .sidebar-item .sidebar-link {
  color: #212529;
}

a {
  color: inherit;
}

#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav ul .sidebar-item .sidebar-link i {
  color: inherit;
}

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: all 2s ease
}

.reveal.active {
  transform: translate(0px);
  opacity: 1;
}

.menuProveedor.sticky.fixed {
  width: 100%;
  /* position: fixed !important; */
  background-color: white;
  /* height: 50px; */
  /* transform: translateX(0px); */
  /* top: 70px */
}

.menuProveedor {
  transition: all .3s ease-in-out;
  height: 50px;
  position: relative !important;
  z-index: 9;
  color: #440099;
}

.menuProveedor.sticky {
  background-color: transparent;
  /* height: 0px; */
  /* transform: translateX(-150px); */
}

#main-wrapper[data-layout=vertical][data-boxed-layout=boxed][data-header-position=fixed][data-sidebartype=full] .menuProveedor {
  max-width: 960px;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

html,
body {
  width: auto;
  height: auto;
}

.sidebar-link {
  justify-content: end;
}

.sidebar-link.has-arrow {
  justify-content: start;
}

.hide-menu {
  min-width: 155px;
}

.text-secondary-kipit {
  color: #808080;
}

.text-success-kipit {
  color: #39b54a;
}

.filepdf div.ant-upload-list.ant-upload-list-text div.ant-upload-list-text-container div.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-text div.ant-upload-list-item-info span.ant-upload-span span.ant-upload-list-item-name {
  max-width: 100px;
}

.motivo-rechazo-frame {
  border: 2px solid #cccccc;
  border-radius: 5px;
  padding: 10px;
}

.k-button-redondeado {
  border-radius: 5px;
  width: 8rem;
}

.form-rechazo>.ant-row.ant-form-item-row {
  justify-content: space-between;
}


.checkbox-black>.ant-checkbox>.ant-checkbox-inner {
  border: 1px solid black;
}

.change-icon::before {
  content: attr(data-content);
}

.bg-ordenrowlogistica,
.bg-ordenrowlogistica>.ant-table-cell-fix-left,
.bg-ordenrowlogistica>.ant-table-cell-fix-right {
  background-color: #e6e6e6 !important;
}

.modal-upload-item>.ant-upload-list>.ant-upload-list-picture-container>.ant-upload-list-item {
  border: none;
  max-width: 338px;
  right: 10px
}

.kipit-color-primary {
  background: #409 !important;
  border-color: #409 !important;
}

.checkout-collapse>.ant-collapse-header>.ant-collapse-header-text {
  width: 100%;
}

.btn-primary-outline {
  background-color: transparent;
  border-color: #409;
  color: #409;
}

.btn-primary-outline:hover {
  background-color: #409;
  border-color: #409;
  color: white;
}

.btn-secondary-outline {
  background-color: transparent;
  border-color: var(--secondary);
  color: var(--secondary);
}

.btn-secondary-outline:hover {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: white;
}


.upload-list-absolute>.ant-upload-list>.ant-upload-list-text-container>.ant-upload-list-item {
  position: absolute;
}


.selected-row {
  background: #d9fadd;
  /* overflow: auto; */
}

.deselected-row {
  /* background: #f0fcf1; */
  background: #ffffff;
}
.demo-editor {
  border: 1px solid rgb(228, 228, 228);
  line-height: 1.1;
  background-color: #ffffff;  /* Cambiar color de fondo */
  color: black;  /* Cambiar color del texto */
}